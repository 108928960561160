import { format } from 'date-fns';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import _ from 'lodash';
import React from 'react';
import {
  PostCardContent,
  PostCardContentLink,
  PostCardHeader,
  PostCardImage,
  PostCardImageLink,
  PostCardLarge,
  PostCardPrimaryTag,
  PostCardStyles,
  PostCardTagsWrapper,
  PostCardTitle,
  PostCardExcerpt,
  PostCardMeta,
  PostCardBylineContent,
} from '../styles/postCard';
import { PageContext } from '../templates/post';
import { AuthorList } from './AuthorList';
export interface PostCardProps {
  post: PageContext;
  large?: boolean;
}

export const PostCard: React.FC<PostCardProps> = ({ post, large = false }) => {
  const date = new Date(post.frontmatter.date);
  const datetime = format(date, 'yyyy-MM-dd');

  return (
    <article
      className={`post-card ${post.frontmatter.image ? '' : 'no-image'} ${
        large ? 'post-card-large' : ''
      }`}
      css={[PostCardStyles, large && PostCardLarge]}
    >
      {post.frontmatter.image && (
        <Link
          className='post-card-image-link'
          css={PostCardImageLink}
          to={post.fields.slug}
        >
          <PostCardImage className='post-card-image'>
            {post.frontmatter?.image?.childImageSharp?.fluid && (
              <Img
                alt={`${post.frontmatter.title} cover image`}
                style={{ height: '100%' }}
                fluid={post.frontmatter.image.childImageSharp.fluid}
              />
            )}
          </PostCardImage>
        </Link>
      )}
      <PostCardContent className='post-card-content'>
        <Link
          className='post-card-content-link'
          css={PostCardContentLink}
          to={post.fields.slug}
        >
          <PostCardHeader className='post-card-header'>
            <PostCardTagsWrapper>
              {post.frontmatter.tags.map(tag => (
                <PostCardPrimaryTag key={tag} className='post-card-primary-tag'>
                  {tag}
                </PostCardPrimaryTag>
              ))}
            </PostCardTagsWrapper>
            <PostCardTitle className='post-card-title'>
              {post.frontmatter.title}
            </PostCardTitle>
          </PostCardHeader>
          <PostCardExcerpt className='post-card-excerpt'>
            <p>{post.frontmatter.excerpt || post.excerpt}</p>
          </PostCardExcerpt>
        </Link>
        <PostCardMeta className='post-card-meta'>
          <AuthorList authors={post.frontmatter.author} tooltip='small' />
          <PostCardBylineContent className='post-card-byline-content'>
            <span>
              {post.frontmatter.author.map((author, index) => {
                return (
                  <React.Fragment key={author.id}>
                    <Link to={`/author/${_.kebabCase(author.id)}/`}>
                      {author.id}
                    </Link>
                    {post.frontmatter.author.length - 1 > index && ', '}
                  </React.Fragment>
                );
              })}
            </span>
            <span className='post-card-byline-date'>
              <time dateTime={datetime}>{datetime}</time>{' '}
              <span className='bull'>&bull;</span> {post.timeToRead} min read
            </span>
          </PostCardBylineContent>
        </PostCardMeta>
      </PostCardContent>
    </article>
  );
};
